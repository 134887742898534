<div class="grid" style="height: 74px;">
  <div class="col-12 header">
    <div class="grid">
      <div class="col-5">
        <div class="logo version">
          <a href="/"><img src="../../../assets/images/logo_pdb.png" /></a>
        </div>
        <div class="version">
          Version
          <div class="version-value">

            1.3.2
          </div>

        </div>
        <div class="version">
          Release Date
          <div class="version-value">

            31/05/2024
          </div>

        </div>
      </div>
      <div class="col-6" style="padding-top: 23px">
        <div style="float: right;">
          <p-menubar [model]="items"></p-menubar>
        </div>
      </div>
      <div class="col-1 text-right">
        <div class="user">
          {{userInitials}}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="grid" *ngIf="router.url === '/'">
  <div class="col-12 pageHeader">
    <div class="headerWelcomeTitle">
      {{message}}, {{FirstName}}
    </div>
  </div>
</div>
<div class="grid layoutPad" style="margin-top: -80px;" *ngIf="router.url === '/'">
  <div class="col homeTiles shadow-4 mr-2 pointer" routerLink="/standard-reports/sfdr-bmr">
    <div class="grid">
      <div class="col-12">
        <div class="homeTilesTitle">
          <i class="pi pi-shield green" style="font-size: 30px;"></i>
          Standard Reports
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 alignRight ">
        <div class="homeTilesTitle">
          <i class="pi pi-chevron-right green" style="font-size: 30px;"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="col homeTiles shadow-4 mr-2 pointer" routerLink="/custom-reports/custom-sfdr-bmr">
    <div class="grid">
      <div class="col-12">
        <div class="homeTilesTitle">
          <i class="pi pi-globe green" style="font-size: 30px;"></i>
          Custom Reports
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 alignRight ">
        <div class="homeTilesTitle">
          <i class="pi pi-chevron-right green" style="font-size: 30px;"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>


<!-- <div class="example-container" [class.example-is-mobile]="mobileQuery.matches">

  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav #snav mode="side" opened="true"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <span *ngFor="let item of menuData">
          <span *ngIf="item.children && item.children.length > 0">
            <mat-accordion [multi]="true">
              <mat-expansion-panel [expanded]="true" >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    
                    <div fxLayout="row" fxLayoutAlign="space-between center"> 
                      <mat-icon>{{item.iconName}}</mat-icon>
                      {{item.menuName}}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <span *ngFor="let child of item.children">
                  <mat-list-item routerLink="{{child.menuUrl}}" (click)="tabChange(child.menuUrl,child.id,child.menuName)">
                    
                    <div fxLayout="row" fxLayoutAlign="space-between center"  > 
                      <mat-icon>{{child.iconName}}</mat-icon>
                      {{child.menuName}}
                    </div>
                  </mat-list-item>
                </span>
              </mat-expansion-panel>
            </mat-accordion>
          </span>
          <span *ngIf="!item.children || item.children.length === 0">
            <mat-list-item routerLink="[item.menuUrl]">
              
              <div fxLayout="row" fxLayoutAlign="space-between center"  (click)="tabChange(item.menuUrl,item.id)">
                <mat-icon>{{item.iconName}}</mat-icon>
                {{item.menuName}}
              </div>
            </mat-list-item>
          </span>
        </span>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content style="  margin-left: 276px;">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div> -->

<p-menu #menu [popup]="true" [model]="items"></p-menu>